import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Tasacion vivienda",
  "image": "../../images/tasacion_casa.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`¿Qué es una tasación de vivienda?`}</h2>
    <p>{`Es un documento que crea un experto cualificado de forma neutral donde se establece el valor de un inmueble mediante el diagnostico de las características del mismo a través de unos criterios generales. Este valor es el precio que posiblemente un comprador estaría dispuesto a pagar por ese inmueble en ese momento, ya que la tasación variará según la demanda, la oferta, la situación económica o las previsiones estatales.  `}</p>
    <h2>{`¿Por qué hay que tasar una vivienda?`}</h2>
    <p>{`Principalmente el motivo de tasar una vivienda es para solicitar una hipoteca por parte del comprador. La tasación es obligatoria ya que el banco tiene que tener una garantía del valor de la vivienda para que en el caso de impago de la mensualidad por parte del hipotecado se pueda cubrir el capital prestado.
Hay otros casos en que se tasa una vivienda cuando está afectada por algún proceso judicial como una herencia, un divorcio o un embargo del titular.
Se puede añadir una tasación cuando el propietario quiere valorar su venta y conocer el precio objetivo que tiene.`}</p>
    <h2>{`¿Cómo tasar una vivienda?`}</h2>
    <p>{`Se distinguen 3 grandes fases en la tasación de un inmueble.
Recopilación y verificación de la documentación.
En esta fase el tasador empieza por la solicitud en el registro de la propiedad de una nota simple para comprobar la titularidad y una copia de la escritura para comprobar que los datos del catastro y ayuntamiento coinciden y que no existen problemas con ilegalidades urbanísticas.
Observación e inspección de la vivienda in situ
El tasador tras el análisis de la documentación debe comprobar in situ la vivienda para hacer el análisis de forma más exhaustiva. Empezando por las mediciones y fotografías del inmueble y valorando las características del mismo. Existe una normativa legal que especifica unas directrices y se tienen en cuenta unos criterios determinados.
Estudio mercado de la zona
Finalmente se realiza un estudio con los inmuebles en venta de la zona ya que van a ser un factor determinante para valorar el precio final de tasación. Se tendrán en cuenta inmuebles de características parecidas. El tasador intentará comprobar cuál es el precio de venta final, es decir, el de escritura y en la mayoría de casos está lejos del que se ofrece en los portales inmobiliarios.`}</p>
    <h2>{`¿Qué factores importan en la tasación de una vivienda?`}</h2>
    <p>{`Existen muchas cualidades que se tienen en cuenta en la tasación, pero lo que más valor da a una vivienda es la ubicación, seguidamente estaría la superficie y orientación. Es muy importante también el estado de conservación y su distribución.
El valor de un piso no es más que multiplicar el precio del metro cuadrado de la zona por su superficie y ajustar ese precio valorando los siguientes puntos.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Ubicación`}</strong>{` `}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Es el factor más importante de una tasación es el precio de las viviendas de la zona ya que va a determinar el rango de tasación en el que se sitúa la vivienda. Una zona cotizada tendrá un valor base muy alto para el tasador. Normalmente la zona puede ganar valor por sus servicios, transporte público o accesos y carreteras, pero no siempre es así ya que se puede tratar por ejemplo de una urbanización privada que no dispone de las anteriores características, pero si está muy cotizada debido a otros parámetros como seguridad, exclusividad y tipo de vivienda.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Superficie`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Las medidas y extensión de la vivienda es otro de los factores más importantes ya que se va a estimar un precio por metro cuadrado y cuantos más metros va a disponer de más valor. Es importante también considerar los metros habitables ya que puede darse el caso de una vivienda que tenga sólo el 80% del total de metros como habitables ya que por disposición o geometría son metros que no tienen funcionalidad.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Orientación`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`La orientación es importantísima para la tasación y es una de las características más revisadas por un posible comprador ya que esta va a determinar tanto la iluminación como la temperatura por el impacto de la luz solar en la vivienda. Esta orientación ideal puede cambiar según el lugar donde se encuentra la vivienda ya que no es lo mismo tener una vivienda en la montaña que en la costa. En general las orientaciones al sur-este son las preferidas. Además, la orientación determina la energía consumida y puede ahorrar costes tanto para aires acondicionados como para calefacciones.`}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`Estado conservación`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`La antigüedad de la vivienda es muy importante, ya que las viviendas más actuales disponen de mejores aislamientos y distribuciones en general y además no tendrán revisiones y derramas tan frecuentemente como un bloque más antiguo.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Distribución`}</strong>{` `}</li>
    </ol>
    <ul>
      <li parentName="ul">{`La forma y distribución de una vivienda es otro de las características que más revisan los comprares ya que diseñan potencialmente como van a quedar sus estancias. `}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Espacios exteriores`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Si una vivienda tiene terraza o jardín privado también aumentará su valor de tasación, son elementos muy buscados por los compradores. También se valoran los espacios comunitarios como piscinas, pistas de pádel o gimnasio.`}</li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><strong parentName="li">{`Aislamientos`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El aislamiento es un parámetro también se valora. Tanto aislamientos acústicos para ruidos como aislamiento térmico para conservar la temperatura.`}</li>
    </ul>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol"><strong parentName="li">{`Ventilación`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Se revisa la ventilación que dispone la vivienda.`}</li>
    </ul>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol"><strong parentName="li">{`Vistas`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Las vistas es un elemento cada vez más importante, sobre todo si viene acompañado de un espacio privado donde se pueda disfrutar.`}</li>
    </ul>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol"><strong parentName="li">{`Inspecciones en regla`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El tasador revisará que las inspecciones están en regla, sobretodo en edificios más antiguos donde las revisiones técnicas ITE tienen un papel fundamental.`}</li>
    </ul>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol"><strong parentName="li">{`Certificado energético`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Se valora la eficiencia energética de la vivienda mediante el certificado energético. Ya que una mayor eficiencia provoca un mayor ahorro en el consumo de energía.`}</li>
    </ul>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol"><strong parentName="li">{`Estado fachada`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El estado de la fachada y su longitud también son revisados.`}</li>
    </ul>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol"><strong parentName="li">{`Reformas`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Si se han realizado reformas que han actualizado la vivienda tendrá un mayor valor que si la vivienda sigue conservando los elementos de origen.`}</li>
    </ul>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol"><strong parentName="li">{`Altura y ascensor`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`En este punto si la vivienda tiene ascensor será mejor valorada y además en este caso cuanto mayor altura más valor se añade al inmueble. Por el contrario, en un edificio sin ascensor, los pisos de menor altura serán los más cotizados.`}</li>
    </ul>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol"><strong parentName="li">{`Calidad de los materiales`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El material de suelos, puertas, ventanas, armarios y sanitarios se tienen en cuenta, incluso la caldera y su antigüedad.`}</li>
    </ul>
    <p>{`Todos estos factores determinan una calidad general del entorno y de la vivienda, que van a aumentar o disminuir la valoración basal determinada por la ubicación.
Una vez recopilada toda la información el tasador tarda entre 48h y 72h en generar el informe según normativa y según unos índices de calidad que serán revisados.`}</p>
    <h2>{`¿Qué caducidad tiene una tasación?`}</h2>
    <p>{`El documento entregado en una tasación tiene una validez legal de 6 meses desde la fecha de emisión. Pasado este lapso de tiempo, será necesario volver a realizar una tasación para solicitar la hipoteca a las entidades bancarias ya que el documento no tendrá validez.`}</p>
    <h2>{`¿Cuánto cuesta tasar una vivienda?`}</h2>
    <p>{`Una tasación tiene un coste variable que se sitúa entre los 300 y los 600 euros.`}</p>
    <h2>{`¿Quién puede tasar una vivienda?`}</h2>
    <p>{`La tasación normalmente la realizan profesionales como arquitectos, arquitectos técnicos, aparejadores, ingenieros agrónomos, ingenieros industriales… dependerá del tipo de inmueble a valorar. Es importante que tengan un conocimiento del mercado inmobiliario y conocer las directrices que se valoran en una tasación.
En primer lugar, se pide un presupuesto y si el cliente está de acuerdo se inicia el proceso de tasación.
Actualmente, si simplemente se quiere conocer el precio de un inmueble tanto para valorar su posible venta como para su posible compra se puede realizar mediante muchos sitios web que disponen de esta funcionalidad.
En cualquier caso, con una estimación online la entidad bancaria no te va a conceder una hipoteca por lo que si este es tu caso necesitarás contactar con un profesional y comprobar que está legalmente acreditado para hacer el informe de tasación para la entidad bancaria ya que es un requisito de obligado cumplimiento para la concesión del préstamo hipotecario.`}</p>
    <h2>{`¿Dónde tasar una vivienda online gratis?`}</h2>
    <p>{`Normalmente en estos sitios solo se debe rellenar la información que se solicita y en algún caso el correo electrónico para que te envíen el detalle. Es un proceso muy simple que no requiere apenas esfuerzo y una manera muy rápida de estimar el precio de una posible tasación de un profesional.`}</p>
    <h2>{`Comparativa tasadores online`}</h2>
    <p>{`Actualmente existen algunas herramientas en webs que ofrecen este servicio de forma gratuita, tanto portales inmobiliarios como bancos.
Para nosotros los mejores tasadores son los siguientes.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.idealista.com/valoracion-inmuebles"
        }}>{`IDEALISTA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.yaencontre.com/tasaciones/datosTasacion"
        }}>{`YAENCONTRE`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.housell.com/valoracion-inmuebles"
        }}>{`HOUSELL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://housfy.com/tasacion-piso"
        }}>{`HOUSFY`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adaix.com/tasacion-vivienda"
        }}>{`ADAIX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bbva.es/personas/experiencias/bbva-valora/analiza-vivienda.html"
        }}>{`BBVA`}</a></li>
    </ul>
    <h2>{`Comparativa de ejemplo de tasación`}</h2>
    <p>{`Hemos realizado una prueba en los tasadores online gratuitos para ver la variación de precios y el proceso.
Adjuntamos la tabla para un piso de 79 metros cuadrados en Barcelona, con código postal 08026, de 3 habitaciones y 1 lavabo y en buen estado.
Tasador online	Precio mínimo	Precio de venta	Precio máximo`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><span style={{
              "marginRight": "20px"
            }}>{`Tasador online`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Precio mínimo`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Precio de venta`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`Precio máximo `}</span></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IDEALISTA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`262.456 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`269.525 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`283.999 €`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HOUSELL`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`287.084 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`310.362 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`333.640 €`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HOUSFY`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`254.262 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`325.978 €`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BBVA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`337.000 €`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`¿Cuáles son las mejores tasadoras de viviendas?`}</h2>
    <p>{`No podemos valorar objetivamente la calidad de tasadora, pero te vamos a listar las que más tasaciones realizan en España ordenadas por cuota de mercado.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><span style={{
              "marginRight": "20px"
            }}>{`Tasadora`}</span></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span style={{
              "marginRight": "20px"
            }}>{`% mercado`}</span></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TINSA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`27,50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GLOVAL VALUATION`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`14,50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span style={{
              "marginRight": "20px"
            }}>{`ST SOCIEDAD DE TASACION`}</span></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13,10%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GESVALT`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`7,20%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CBRE`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5,70%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EUROVAL`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4,50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JLL VALORACIONES`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4,10%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KRATA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3,90%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VALMESA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3,80%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UVE VALORACIONES`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3,40%`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      